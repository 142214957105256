;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4e7d67a5e7e731fe80b50c2149c0a4c27221ee74"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { SENTRY_DSN } from 'app/config'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    // new Sentry.Replay({
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
  ],
})
