import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme'
import { Provider as AppProvider } from 'app/provider'
import Head from 'next/head'
import React, { useState } from 'react'
import Header from 'app/features/layout/header'
import Main from 'app/features/layout/main'
import AccountInfoBanner from 'app/features/layout/accountInfoBanner'

export default function Layout({
  children,
  hideNavigation,
  hideAside,
  hideHeader,
}: {
  children: React.ReactNode
}) {
  const [theme, setTheme] = useRootTheme()
  const [accountInfoBannerHeight, setAccountInfoBannerHeight] = useState(0)

  return (
    <>
      <Head>
        <title>BlackPlanet</title>
        <meta name="description" content="BlackPlanet" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" crossOrigin="use-credentials" />
      </Head>
      <NextThemeProvider
        onChangeTheme={(next) => {
          setTheme(next as any)
        }}
      >
        <AppProvider disableRootThemeClass defaultTheme={'light'}>
          <AccountInfoBanner setAccountInfoBannerHeight={setAccountInfoBannerHeight} />
          <Header accountInfoBannerHeight={accountInfoBannerHeight} hideHeader={hideHeader} />
          <Main
            accountInfoBannerHeight={accountInfoBannerHeight}
            hideNavigation={hideNavigation}
            hideAside={hideAside}
          >
            {children}
          </Main>
        </AppProvider>
      </NextThemeProvider>
    </>
  )
}
