import { XStack, YStack, useMedia, Spinner } from '@bp/ui'
import Navigation from './navigation'
import Aside from './aside'
import React from 'react'
import { useAuth } from 'app/lib/auth'

export default function Main({
  accountInfoBannerHeight,
  children,
  hideAside = false,
  hideNavigation = false,
}) {
  const media = useMedia()

  return (
    <>
      {media.gtXl ? (
        <XStack fg={1} fs={1}>
          {!hideNavigation ? (
            <YStack w={280} p={10}>
              <Navigation accountInfoBannerHeight={accountInfoBannerHeight} />
            </YStack>
          ) : null}

          <XStack fg={1} fs={1}>
            <YStack fg={1} fs={1}>
              {children}
            </YStack>
            {!hideAside ? (
              <YStack w={322} p={10}>
                <Aside />
              </YStack>
            ) : null}
          </XStack>
        </XStack>
      ) : (
        <YStack fg={1}>
          {!hideNavigation ? (
            <YStack alignItems="center" justifyContent="center" height="84px">
              <Navigation accountInfoBannerHeight={accountInfoBannerHeight} />
            </YStack>
          ) : null}

          <XStack fg={1} fs={1}>
            <YStack fg={1} fs={1}>
              {children}
            </YStack>
            {media.gtLg && !hideAside ? (
              <YStack w={310} p={10}>
                <Aside />
              </YStack>
            ) : null}
          </XStack>
        </YStack>
      )}
    </>
  )
}
