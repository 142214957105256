import { XStack, Text, View, ExclamationCircleIcon, isWeb, YStack, Button } from '@bp/ui'
import { useEffect, useRef } from 'react'
import { useUserInfoStore } from 'app/store'
import { useRouter } from 'solito/router'
import { Linking, Pressable } from 'react-native'
import { EMAIL_STATUS, ACCOUNT_STATUS } from 'app/utils/constants'
import { useAuth } from 'app/lib/auth'

export default function AccountInfoBanner({ setAccountInfoBannerHeight }) {
  const { user } = useAuth()
  const accountStatus = useUserInfoStore((store) => store.account_status)
  const emailStatus = useUserInfoStore((store) => store.email_status)
  const permissions = useUserInfoStore((store) => store.permissions)
  const permissionSuspended =
    !permissions.browse ||
    !permissions.chat ||
    !permissions.post ||
    !permissions.react ||
    !permissions.send_dm
  const { push } = useRouter()
  const contentRef = useRef(null)
  let message = ''

  if (permissionSuspended) {
    message = (
      <YStack>
        <YStack gap="$3">
          <Text color="$lightest">
            You have limited access to BlackPlanet because your rights have been suspended.
          </Text>
          <Pressable
            onPress={() =>
              Linking.openURL(
                'https://help.blackplanet.com/safety-and-security/account-limited-access/'
              )
            }
          >
            <Text color="$lightest">
              Please click{' '}
              <Text color="$lightest" fontWeight="bold">
                here
              </Text>{' '}
              to learn more.
            </Text>
          </Pressable>
        </YStack>
      </YStack>
    )
  }
  if (emailStatus !== EMAIL_STATUS.verified) {
    message = (
      <YStack>
        <YStack gap="$3">
          <Text color="$lightest">
            Your access was restricted due to an issue with your email address.
          </Text>
          <Pressable onPress={() => push('/settings')}>
            <Text color="$lightest">
              Please update your email on the{' '}
              <Text color="$lightest" fontWeight="bold">
                settings page
              </Text>{' '}
              and try again.
            </Text>
          </Pressable>
        </YStack>
      </YStack>
    )
  }
  if (accountStatus === ACCOUNT_STATUS.suspended) {
    message = (
      <YStack>
        <YStack gap="$3">
          <Text color="$lightest">
            You have limited access to BlackPlanet because your account have been suspended.
          </Text>
          <Pressable
            onPress={() =>
              Linking.openURL(
                'https://help.blackplanet.com/safety-and-security/account-suspension/'
              )
            }
          >
            <Text color="$lightest">
              Please click{' '}
              <Text color="$lightest" fontWeight="bold">
                here
              </Text>{' '}
              to learn more.
            </Text>
          </Pressable>
        </YStack>
      </YStack>
    )
  }

  useEffect(() => {
    if (!message || !isWeb || !user) return

    const updateHeight = () => {
      if (contentRef.current && setAccountInfoBannerHeight) {
        setAccountInfoBannerHeight(contentRef.current.offsetHeight)
      }
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [setAccountInfoBannerHeight])

  if (!message || !user) return null

  return (
    <XStack
      ref={contentRef}
      backgroundColor="$errorDarker"
      padding="$5"
      alignItems="center"
      gap="$3"
      zIndex={999}
      top={0}
      position="fixed"
      width="100%"
    >
      <ExclamationCircleIcon color="$lightest" size="$3" flexShrink={0} />
      <View flex={1}>{message}</View>
    </XStack>
  )
}
