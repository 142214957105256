/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import {
  Button,
  YStack,
  useMedia,
  HomeIcon,
  DiscoverIcon,
  PlayCircleOutlineIcon,
  ChatIcon,
} from '@bp/ui'
import { useLink } from 'solito/link'

export default function Navigation({ accountInfoBannerHeight }) {
  const media = useMedia()

  const homeProps = useLink({
    href: '/',
  })

  const discoverProps = useLink({
    href: '/discover',
  })

  const chatsProps = useLink({
    href: '/chats',
  })

  const watchProps = useLink({
    href: '/watch',
  })

  return (
    <YStack
      flexDirection={media.gtXl ? 'column' : 'row'}
      bc="$background"
      $xl={{
        gap: '$8',
        position: 'fixed',
        top: 72 + accountInfoBannerHeight,
        height: 84,
        zIndex: 998,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      $gtSm={{
        borderBottomWidth: 1,
        borderBottomColor: '$light',
      }}
      $gtXl={{
        borderBottomWidth: 0,
      }}
    >
      <Button {...homeProps} variant="link" icon={<HomeIcon size={24} />}>
        {!media.xs && 'Home'}
      </Button>
      <Button {...discoverProps} variant="link" color="black" icon={<DiscoverIcon size={24} />}>
        {!media.xs && 'Discover'}
      </Button>
      <Button {...chatsProps} variant="link" color="black" icon={<ChatIcon size={24} />}>
        {!media.xs && 'Chats'}
      </Button>
      <Button
        {...watchProps}
        variant="link"
        color="black"
        icon={<PlayCircleOutlineIcon size={24} />}
      >
        {!media.xs && 'Watch'}
      </Button>
    </YStack>
  )
}
