/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import { useAuth } from 'app/lib/auth'
import { useLink } from 'solito/link'
import {
  DelayedTextField,
  Button,
  XStack,
  SearchIcon,
  EmailIcon,
  NotificationsIcon,
  useMedia,
  YStack,
  Text,
  Avatar as BareAvatar,
  NiceLink,
  Circle,
  Stack,
} from '@bp/ui'

import Logo from './logo'
import { useState } from 'react'
import Avatar from './avatar'
import Bell from '../bell'
import API from 'app/api'
import { useStream } from 'app/lib/stream/useStream'
import { useRouter } from 'solito/router'
import useClickAway from 'app/hooks/useClickAway'

export default function Header({ hideHeader, accountInfoBannerHeight }) {
  const { user, isLoading, login, signup, accessToken } = useAuth()
  const media = useMedia()
  const newMessageIndicator = useStream((s) => s.newMessageIndicator)
  const resetNewMessageIndicator = useStream((s) => s.resetNewMessageIndicator)
  const { push } = useRouter()
  const searchResultsRef = useClickAway(() => {
    setSearchResults(null)
  })

  const messagesProps = useLink({
    href: '/messages',
  })
  const handleMessage = () => {
    resetNewMessageIndicator()
    push('/messages')
  }

  const [searchResults, setSearchResults] = useState(null)
  const handleSearch = async (value) => {
    const searchResults = await API.search.getPeople(value?.toLowerCase())
    setSearchResults(searchResults.data)
  }

  if (hideHeader) {
    return null
  }

  return (
    <XStack height={72 + accountInfoBannerHeight}>
      <XStack
        position="fixed"
        height={72}
        elevation={3}
        width="100%"
        alignItems="center"
        backgroundColor="$lightest"
        zIndex="999"
        justifyContent="space-between"
        px="$4"
        gap="$6"
        top={accountInfoBannerHeight}
      >
        <XStack>
          <Logo scale={0.75} />
        </XStack>
        <YStack $xxs={{ display: 'none' }} flexShrink={1} flexGrow={1}>
          <DelayedTextField
            flexShrink={1}
            flexGrow={1}
            placeholder="Search for people"
            accessibilityLabel="Search for people"
            onChangeText={handleSearch}
            startIcon={<SearchIcon />}
          />
          {searchResults && (
            <YStack
              ref={searchResultsRef}
              position="absolute"
              top={43}
              background="$lightest"
              padding="$5"
              borderRadius="$4"
              borderColor="$light"
              borderWidth={1}
              gap="$4"
              alignItems="flex-start"
            >
              {searchResults.length > 0 &&
                searchResults.map((user, index) => (
                  <NiceLink
                    href={`/user/${user.username}`}
                    onPress={() => {
                      setSearchResults(null)
                    }}
                    key={index}
                  >
                    <XStack gap="$3" key={user.id} alignItems="center">
                      <BareAvatar circular size="$4" src={user.avatar?.md} />
                      <YStack gap="$2">
                        <Text>{user.nickname}</Text>
                        <Text variant="footnote" color="$medium">
                          @{user.username}
                        </Text>
                      </YStack>
                    </XStack>
                  </NiceLink>
                ))}
              {searchResults.length === 0 && (
                <XStack gap="$3" alignItems="center" paddingHorizontal="$5">
                  <Text>No results found</Text>
                </XStack>
              )}
            </YStack>
          )}
        </YStack>

        {accessToken && user && !isLoading ? (
          <XStack gap="$4">
            {media.xxs && (
              <Button
                variant="text"
                {...messagesProps}
                icon={<SearchIcon size={24} />}
                gtXss={{ display: 'none' }}
              />
            )}
            <Stack ai="center" jc="center">
              {newMessageIndicator && (
                <Circle
                  size={10}
                  backgroundColor="$errorDarker"
                  pointerEvents="none"
                  position="absolute"
                  bottom={10}
                  right={0}
                  zIndex={99}
                ></Circle>
              )}

              <Button variant="text" onPress={handleMessage} icon={<EmailIcon size={24} />} />
            </Stack>

            <Bell />
            <Avatar />
          </XStack>
        ) : (
          <XStack gap="$2">
            <Button variant="primary" onPress={login}>
              Login
            </Button>
            <Button variant="accent" onPress={signup}>
              Signup
            </Button>
          </XStack>
        )}
      </XStack>
    </XStack>
  )
}
